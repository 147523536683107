import React from 'react';
import BigNumber from 'bignumber.js';
import Dashboard from 'pages/Dashboard';
import Presale from 'pages/Presale';
import { useWeb3React } from '@web3-react/core';
import { useFetchPublicData, useFetchPriceList } from 'store/hooks';
import useEagerConnect from 'hooks/useEagerConnect';
import TopNavbar from 'components/TopNavbar';
import {Row, Col} from 'react-bootstrap';
// import Banner from './components/Banner';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

import 'style/App.scss';
import 'react-toastify/dist/ReactToastify.css';



function App() {
  useEagerConnect();
  useFetchPublicData();
  useFetchPriceList();
  const { account } = useWeb3React();

  return (
    <>
      <Router>
        <TopNavbar account={account}></TopNavbar>
        {/* <Banner text={"Base Network MEME trading activity is at the ATH | GME 🔥 | "}/> */}
        <Routes>
          <Route path="/:ref" element={<Dashboard account={account} />} />
          <Route path="/" element={<Dashboard account={account} />} />
          <Route path="/presale" element={<Presale account={account} />} />

          {/* <Route path="/mechanics" element={MechanicsPage} />
        <Route path="/faq" element={FaqPage} />
        <Route path="/research" element={ResearchPage} /> */}
        </Routes>
        <Row className='footer d-flex justify-content-between justify-content-md-center px-5 pb-4'>
          <Col xs={12} className='text-center py-4'>
            <span>MSITH © Copyright 2024</span>
          </Col>
          <Col xs={3} className='text-center'>
            <a href={process.env.REACT_APP_GITBOOK_URL} target='_blank'>GitBook</a>
          </Col>
          <Col xs={3} className='text-center'>
            <a
              href={"https://3476467493-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2FeuQ69694gGwuGjHACW2Y%2Fuploads%2FU2o7H3fpTkEPkJ3UG9ic%2Faudit_report_MSITH.pdf?alt=media&token=a451b2d8-6571-4446-8965-9986f80ba08f"}
              target='_blank'>Audit</a>
          </Col>
          <Col xs={3} className='text-center'>
            <a href={process.env.REACT_APP_TELEGRAM} target='_blank'>Telegram</a>
          </Col>
        </Row>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
