import { useMemo } from 'react';
import useWeb3 from 'hooks/useWeb3';
import { getBep20Contract, getTheAnalystContract } from 'utils/contractHelpers';
import { getPresaleContract } from 'utils/contractHelpers';

export const useTheAnalyst = () => {
  const web3 = useWeb3();
  return useMemo(() => getTheAnalystContract(web3), [web3]);
};

export const usePresaleContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getPresaleContract(web3), [web3]);
};

export const useERC20 = address => {
  const web3 = useWeb3();
  return useMemo(() => getBep20Contract(address, web3), [address, web3]);
};
