import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useAppDispatch } from 'store';
import { updateUserStakedBalance, updateUserBalance, updateUserPendingReward } from 'store/actions';
import { useTheAnalyst } from './useContract';
import { compound } from 'utils/callHelpers';

export const useCompound = () => {
  const dispatch = useAppDispatch();
  const { account } = useWeb3React();
  const degenHallContract = useTheAnalyst();

  const handleCompound = useCallback(
    async () => {
      await compound(degenHallContract, account);

      dispatch(updateUserStakedBalance(account));
      dispatch(updateUserBalance(account));
      dispatch(updateUserPendingReward(account));
    },
    [account, dispatch, degenHallContract],
  );

  return { onCompound: handleCompound };
};

export default useCompound;
