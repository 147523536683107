import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useAppDispatch } from 'store';
import { updateUserStakedBalance, updateUserBalance } from 'store/actions';
import { deposit } from 'utils/callHelpers';
import { useTheAnalyst } from './useContract';
import { getWeb3NoAccount } from 'utils/web3';
import queryString from 'query-string'

export const useStake = () => {

  const dispatch = useAppDispatch();
  const { ref } = queryString.parse(location.search)
  const { account } = useWeb3React();
  const degenHallContract = useTheAnalyst();

  const handleStake = useCallback(
    async (amount) => {
      const web3 = getWeb3NoAccount();
      const isValidRef = web3.utils.isAddress(ref);
      // console.log(`ref: ${ref}`)
      // console.log(`validRef: ${isValidRef}`)
      // console.log(`Account: ${account}`)
      // console.log(`Amount: ${amount}`)
      await deposit(degenHallContract, amount, 18, account, isValidRef ? ref : account);

      dispatch(updateUserStakedBalance(account));
      dispatch(updateUserBalance(account));
    },
    [account, dispatch, degenHallContract, ref],
  );

  return { onStake: handleStake };
};

export default useStake;
