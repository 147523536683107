const BigNumber = require('bignumber.js');
export const approve = async (contract, spender, account) => contract.methods
  .approve(spender, "100000000000000000000000000")
  .send({ from: account, gas: 400000 })
  .on('transactionHash', tx => tx.transactionHash)

export const allowance = async (contract, sender, spender) => contract.methods
  .allowance(sender, spender)
  .call();

export const deposit = async (
  contract,
  amount,
  decimals = 18,
  account,
  referall = account,
) => {
    let bnAmount = new BigNumber(amount).shiftedBy(decimals);
    
    const gas = await contract.methods
      .buyPoints(referall)
      .estimateGas({
        from: account,
        value: bnAmount.toString(),
      });
    return contract.methods
      .buyPoints(referall)
      .send({
        from: account,
        gas,
        value: bnAmount.toString(),
      }).on('transactionHash', tx => tx.transactionHash);
}

export const buyPresale = async (
  contract,
  amount,
  decimals = 18,
  account
) => {
    let bnAmount = new BigNumber(amount).shiftedBy(decimals);
    
    const gas = await contract.methods
      .buy()
      .estimateGas({
        from: account,
        value: bnAmount.toString(),
      });
    return contract.methods
      .buy()
      .send({
        from: account,
        gas,
        value: bnAmount.toString(),
      }).on('transactionHash', tx => tx.transactionHash);
}

export const claimPresale = async (
  contract,
  account
) => {    
    const gas = await contract.methods
      .claim()
      .estimateGas({from: account});
    return contract.methods
      .claim()
      .send({from: account, gas})
      .on('transactionHash', tx => tx.transactionHash);
}

export const compound = async (contract, account, referall = account) => {
  const gas = await contract.methods
  .hatchPoints(referall)
  .estimateGas({ from: account });
  return contract.methods
    .hatchPoints(referall)
    .send({ from: account, gas })
    .on('transactionHash', tx => tx.transactionHash);
}

export const claim = async (contract, account) => {
  const gas = await contract.methods
    .sellPoints()
    .estimateGas({ from: account })
  return contract.methods
    .sellPoints()
    .send({ from: account, gas })
    .on('transactionHash', tx => tx.transactionHash);
}

export const balance = async (web3, address) => await web3.eth.getBalance(address);
