import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { Button, Card, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { useBlock } from 'store/hooks';
import { getBalanceNumber, formatNumber } from 'utils/formatBalance';
import StakeComponent from 'components/StakeComponent';
import { useCompound } from 'hooks/useCompound';
import { useClaim } from 'hooks/useClaim';
import { ArrowRepeat } from 'react-bootstrap-icons';
import CountUp from 'react-countup';
import useAuth from 'hooks/useAuth';
import usePrice from 'hooks/usePrice';
import { isAddressBlacklisted } from 'utils/blacklist';

const Hall = function ({ hall }) {
  const { totalStaking, userData } = hall;
  const { login } = useAuth();

  const { onCompound } = useCompound();
  const { onClaim } = useClaim();
  const { account } = useWeb3React();
  const { currentBlock } = useBlock();
  const [blacklisted, setBlacklisted] = useState(true);
  const priceFeed = usePrice();

  const [ethPrice, setEthPrice] = useState(0);
  const [compounding, setCompounding] = useState(false);
  const [claiming, setClaiming] = useState(false);
  const [staking, setStaking] = useState(false);

  const isLoading = account && (!userData || !currentBlock);

  const stakedBalance = new BigNumber(userData?.workers ? userData.workers : 0);
  const pendingRewards = new BigNumber(userData?.pendingRewards ? userData.pendingRewards : 0);

  const formattedStakeBalance = stakedBalance.toNumber();
  const formattedPendingReward = Number(formatNumber(getBalanceNumber(pendingRewards, 18), 4, 18));
  const [pendingUSD, setPendingUSD] = useState(0);
  const totalValueLocked = Number(totalStaking);

  const [tvlUSD, setTvlUSD] = useState(0);

  const handleCompound = useCallback(async () => {

    setCompounding(true);
    
    try {
      await onCompound();
      toast.success(`Transaction successfull!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      });
    } catch (err) {
      console.error(err);
      toast.error(`Transaction encountered an error!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      });
    }

    setCompounding(false);
  }, [onCompound]);

  const handleClaim = useCallback(async () => {

    setClaiming(true);
    
    try {
      await onClaim();
      toast.success(`Transaction successfull!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      });
    } catch (err) {
      console.error(err);
      toast.error(`Transaction encountered an error!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      });
    }

    setClaiming(false);
  }, [onClaim]);

  useEffect(() => {
    if(priceFeed)
      setEthPrice(Number(priceFeed['usd']));
  },[priceFeed])

  useEffect(() => {
    if(ethPrice > 0 && totalValueLocked)
      setTvlUSD(ethPrice * totalValueLocked)
    if(ethPrice && pendingRewards.gt(0))
      setPendingUSD(Number(getBalanceNumber(pendingRewards, 18) * ethPrice));
  },[ethPrice, totalValueLocked, pendingRewards])

  useEffect(() => {
    if(account){
      console.log(isAddressBlacklisted(account))
      setBlacklisted(isAddressBlacklisted(account));
    }
  }, [account]);



  return (
    <Card className="pool">
      <Card.Header>
        <h4 className="text-uppercase text-center font-weight-bold text-title mb-0">MSITH BALANCER</h4>
      </Card.Header>
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center row-apr">
          <div className="font-medium apy">Wallet&apos;s available ETH:</div>
          {!userData || isNaN(userData.walletBalance) ? (
            <Skeleton width="82px" height="16px" />
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              <div className="text-value font-medium bold">
                <span>{formatNumber( userData.walletBalance * (10**-18), 2, 4)} ETH</span>
              </div>
            </div>
          )}
        </div>

        <div className="d-flex justify-content-between align-items-center row-tvl">
          <div className="font-medium apy">
            MSITH trading liquidity:
          </div>
          {!totalValueLocked || isNaN(totalValueLocked) === undefined ? (
            <Skeleton width="82px" height="16px" />
          ) : (
            
            <div className="d-flex justify-content-center align-items-center row-apr">
              {/* <CountUp
                start={0}
                preserveValue={true}
                end={totalValueLocked}
                delay={0}
                suffix="ETH ="
                duration={1}
                decimals={3}
              >
                {({ countUpRef }) => (
                  <div className="text-value" style={{fontSize: '0.7rem', marginRight: '10px'}}>
                    <span ref={countUpRef} />
                  </div>
                )}
              </CountUp> */}
              <CountUp
                start={0}
                preserveValue={true}
                end={tvlUSD}
                delay={0}
                suffix=" $"
                duration={1}
                decimals={2}
              >
                {({ countUpRef }) => (
                  <div className="text-value font-medium bold">
                    <span ref={countUpRef} />
                  </div>
                )}
              </CountUp>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-end align-items-end mt-0 pt-0">
              <small style={{fontSize: '0.5rem'}}>{totalValueLocked.toFixed(3)} ETH</small>
        </div>

        <div className="d-flex justify-content-between align-items-center row-tvl">
          <div className="font-medium apy">
            Your AI-Workers:
          </div>
          {!userData || isNaN(userData?.stakedBalance) === undefined ? (
            <Skeleton width="82px" height="16px" />
          ) : (
            <div className="d-flex justify-content-center align-items-center row-apr">
              <CountUp
                start={0}
                preserveValue={true}
                end={formattedStakeBalance}
                delay={0}
                duration={2}
              >
                {({ countUpRef }) => (
                  <div className="text-value font-medium bold">
                    <span ref={countUpRef} />
                  </div>
                )}
              </CountUp>
            </div>
          )}
        </div>

        {account && (
          <>
            {isLoading ? (
              <Skeleton width="100%" height="42px" />
            ) : (
              <>
                <StakeComponent
                  pendingTx={compounding || claiming}
                  isStaking={staking}
                  setStaking={setStaking}
                  ethBalance={userData.walletBalance}
                />
              </>
            )}
          </>
        )}
      </Card.Body>

      <Card.Footer>
        <div className="d-flex justify-content-between align-items-center">
          <div className="font-medium apy">Claimable profits:</div>
          {
            !userData || userData?.pendingRewards === undefined ?
              <Skeleton width="82px" height="32px" /> :
              <div className="d-flex justify-content-center align-items-center row-apr">
                <CountUp
                  start={0}
                  preserveValue={true}
                  end={pendingUSD}
                  delay={0}
                  suffix=" $"
                  duration={1}
                  decimals={4}
                >
                  {({ countUpRef }) => (
                    <div className="text-value font-medium bold">
                      <span ref={countUpRef} />
                    </div>
                  )}
                </CountUp>
              </div>
          }
        </div>
        <div className="d-flex justify-content-start align-items-center">
          <small style={{fontSize: '0.5rem'}}>{formattedPendingReward.toFixed(8)} ETH</small>
        </div>

        <Row className={`d-flex justify-content-center ${!blacklisted && 'justify-content-md-between'} align-items-center`}>
          {
            !account 
              ? <Button className='btn btn-primary border-white py-1 mt-3 mt-md-0 border-1 mx-auto' onClick={login}>Connect Wallet</Button>
              : <>
                <Col md={6} xs={10}>
                  <Button
                    className="w-100 mt-2 btn-white py-1"
                    disabled={!formattedPendingReward > 0 || compounding || claiming || staking}
                    onClick={handleCompound}
                  >
                    {(!compounding) ? 'Rebuy' : 'Confirm transaction...'}
                    {(compounding) && (<ArrowRepeat className="icon rotate ml-2" />)}
                  </Button>
                </Col>
                {
                !blacklisted && 
                  <Col md={6} xs={10}>
                    <Button
                      className="w-100 mt-2 btn-primary py-1"
                      disabled={!formattedPendingReward > 0 || claiming || compounding || staking}
                      onClick={handleClaim}
                    >
                      {(!claiming) ? 'Withdraw' : 'Withdrawing'}
                      {(claiming) && (<ArrowRepeat className="icon rotate ml-2" />)}
                    </Button>
                  </Col>
                }
              </>
          }
        </Row>
      </Card.Footer>
    </Card>
  );
};

Hall.propTypes = {
  hall: PropTypes.object
};

export default Hall;
