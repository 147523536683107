import BigNumber from 'bignumber.js';
import {utils} from 'web3';
export { default as contractAddress } from './contracts';
export { default as tokenAddress } from './token';
export { default as presaleAddress } from './presale';

export const FIXED_APR = 5427.55;
export const DEV_FEES = 1.8;
export const MARKETING_FEES = 1.2;
export const DEPOSIT_MIN = 1000000000000000;
export const COMPOUND = 15;
export const START_BLOCK = 19145990;
export const APPROVE_MIN = new BigNumber(1000000).shiftedBy(18);
export const PRESALE_MAX_BUY_SIZE = 0.018
export const PRESALE_MAX_BUY_SIZE_BN = utils.toWei(String(PRESALE_MAX_BUY_SIZE));
export const DEFAULT_TOKENS_PER_ETH = 300000000
export const DEFAULT_TOKENS_PER_ETH_BN = utils.toWei(String(DEFAULT_TOKENS_PER_ETH));

export const getWhitepaper = () => ""// `${process.env.PUBLIC_URL}/docs/MSITH_WhitePaper.pdf`;
