import React, {useEffect, useState} from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { propTypes } from 'react-bootstrap/esm/Image';
import useAuth from 'hooks/useAuth';
import usePrice from 'hooks/usePrice';

const TopNavbar = function ({account}) {
  const { login } = useAuth();
  const priceFeed = usePrice();
  const [ethPrice, setEthPrice] = useState(0);

  useEffect(() => {
    if(priceFeed)
      setEthPrice(Number(priceFeed['usd']));
  },[priceFeed])

  return (
    <div id="sticky-wrapper" className="sticky-wrapper">
      <Navbar expand="md" className="crypto_nav bg-faded fixed-top">

        <Container fluid>

          {/* Mobile Only */}
          <Navbar.Brand className="d-md-none mt-md-3 text-white font-weight-bold text-uppercase" href={'/'}>
            <img src={window.location.origin + "/images/logo.png"} height={'50px'}/>&nbsp;
            MSith
          </Navbar.Brand>
          <ul className="navbar-nav ml-auto d-md-none">
            <li className="nav-item">
              {
                account && <small className='text-white'>{account.substring(0, 3) + '..' + account.substring(account.length - 5, account.length)}</small>
              }
            </li>
          </ul>
          <Navbar.Brand className="d-md-none ml-auto mr-0 mt-md-3">
            <a className="navbar-brand" href={process.env.REACT_APP_TELEGRAM} target={"_blank"} rel="noreferrer">
              <img src={window.location.origin + "/images/TEL.png"} className="social-icon" alt="logo" />
            </a>
          </Navbar.Brand>

          <Navbar.Collapse id="navbarSupportedContent">
            
            <Nav className="navbar-nav d-none d-md-flex">
              <li className="nav-item">
                <a className="nav-text font-weight-bold text-logo text-decoration-none" href="/">
                  <img src={window.location.origin + "/images/logo.png"} height={'50px'}/>
                  &nbsp;MSith
                </a>
              </li>
              
            </Nav>

            { /* Links */}
            {/* <ul className="navbar-nav d-none d-md-flex mr-auto">
              <li className="nav-item align-items-center d-flex ml-5">
                <Link to="/docs/MSITH_WhitePaper.pdf" target="_blank" download className="nav-text text-white">Whitepaper</Link>
              </li>
            </ul> */}

            {/* Account */}
            <ul className="navbar-nav ml-auto pr-3 d-none d-md-flex">
              <li className="nav-item text-white align-items-center">
                <span className='font-medium'>The best AI-powered MEME Trading Bot on the Base network</span>
              </li>
            </ul>

            <ul className="navbar-nav ml-auto pr-3 d-none d-md-flex">
              <li className="nav-item text-white align-items-center">
                <span className='font-medium'>ETH/USD {'=>'} {ethPrice}</span>
              </li>
            </ul>

            <ul className="navbar-nav ml-auto px-3 d-none d-md-flex">
              <li className="nav-item">
                {
                  account 
                    ? <small className='text-white'>{account.substring(0, 3) + '..' + account.substring(account.length - 5, account.length)}</small>
                    : <button className='btn btn-primary border-white px-5 py-1 mt-3 mt-md-0' onClick={login}>Connect Wallet</button>
                }
              </li>
            </ul>

            { /* Links */}
            <ul className="navbar-nav d-none d-md-flex ml-md-5">
              <li className="nav-item">
                <a className="navbar-brand" href={process.env.REACT_APP_TELEGRAM} target={"_blank"} rel="noreferrer">
                  <img src={window.location.origin + "/images/TEL.png"} className="social-icon" alt="logo" />
                </a>
              </li>
            </ul>
          </Navbar.Collapse >

        </Container>
      </Navbar>
    </div>
  );
};

TopNavbar.propTypes = {
  account: propTypes.string
}

export default TopNavbar;
