// export type PresaleStatus {
//     CLOSE,
//     OPEN,
//     CLAIM
// }
import { getWeb3NoAccount } from "utils/web3";
import { getPresaleContract } from "utils/contractHelpers";

export const fetchPresaleStatus = async () => { 
    const web3 = getWeb3NoAccount();
    const contract = getPresaleContract(web3);
  
    try {
      const status = await contract.methods.status().call();
      return {status: Number(status)};
    } catch (err) {
      console.log(err.message);
    }
};