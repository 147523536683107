import BigNumber from 'bignumber.js';
import { getWeb3NoAccount } from 'utils/web3';
import { contractAddress } from 'config/constants';
import { getAddress } from 'utils/addressHelpers';

export const fetchHallTotalStaking = async () => {
  const web3 = getWeb3NoAccount();
  const totalStaked = await web3.eth.getBalance(getAddress(contractAddress));
  const parsed = web3.utils.fromWei(totalStaked);
  return parsed;
};

// export const fetchHallStartBlock = async () => {
//   const web3 = getWeb3NoAccount();
//   const contract = getDegenHallContract(web3);
//   return await contract.methods.startsAt().call();
// };
