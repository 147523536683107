import { createSlice } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { contractAddress } from 'config/constants';
import { getAddress } from 'utils/addressHelpers';
import { fetchHallTotalStaking } from './fetchAnalyst';
import { fetchUserBalance, fetchUserPendingRewards, fetchUserWorkers } from './fetchAnalystUser';

const initialState = { data: {
  address: getAddress(contractAddress)
}};

export const HallSlice = createSlice({
  name: 'Hall',
  initialState,
  reducers: {
    setHallPublicData: (state, action) => {
      const liveData = action.payload;
      state.data = { ...state.data, ...liveData };
    },
    setHallUserData: (state, action) => {
      const userData = action.payload;
      state.data = { ...state.data, userData };
    },
    updateHallUserData: (state, action) => {
      const { field, value } = action.payload;
      state.data = { ...state.data, userData: { ...state.data.userData, [field]: value } };
    },
  },
});

// Actions
export const { setHallPublicData, setHallUserData, updateHallUserData } = HallSlice.actions;

// Thunks
export const fetchHallPublicDataAsync = () => async dispatch => {
  let totalStaking;

  try {
    totalStaking = await fetchHallTotalStaking();
  } catch (err) {
    console.log(err);
    totalStaking = new BigNumber(0).toJSON();
  }

  const liveData = { totalStaking };
  dispatch(setHallPublicData(liveData));
};

export const fetchHallUserDataAsync = account => async dispatch => {
  const balance = await fetchUserBalance(account);
  const workers = await fetchUserWorkers(account);
  const pendingRewards = await fetchUserPendingRewards(account);

  const userData = {
    walletBalance: balance,
    workers: workers,
    pendingRewards: pendingRewards,
  };

  dispatch(setHallUserData(userData));
};

export const updateUserBalance = account => async dispatch => {
  const tokenBalance = await fetchUserBalance(account);
  dispatch(
    updateHallUserData({
      field: 'stakingTokenBalance',
      value: tokenBalance,
    }),
  );
};

export const updateUserStakedBalance = () => async dispatch => {
  const stakedBalance = new BigNumber(0);//await fetchUserStakeBalance(account);
  dispatch(updateHallUserData({ field: 'stakedBalance', value: stakedBalance }));
};

export const updateUserPendingReward = account => async dispatch => {
  const pendingRewards = await fetchUserPendingRewards(account);
  dispatch(updateHallUserData({ field: 'pendingReward', value: pendingRewards }));
};

export default HallSlice.reducer;
