import BigNumber from 'bignumber.js';
import { getWeb3NoAccount } from 'utils/web3';
import { getTheAnalystContract, getBep20Contract } from 'utils/contractHelpers';
import { getAddress } from 'utils/addressHelpers';
import { tokenAddress, contractAddress } from 'config/constants';

export const fetchUserBalance = async account => {
  const web3 = getWeb3NoAccount();
  // const tokenContract = getBep20Contract(getAddress(tokenAddress), web3);
  // const balance = await tokenContract.methods.balanceOf(account).call();
  const balance = await web3.eth.getBalance(account);
  return new BigNumber(balance).toJSON();
};

export const fetchUserAllowance = async account => {
  const web3 = getWeb3NoAccount();
  const tokenContract = getBep20Contract(getAddress(tokenAddress), web3);
  try{
    const allowance = await tokenContract.methods.allowance(account, getAddress(contractAddress)).call();
    return new BigNumber(allowance).toJSON();
  }catch(err){console.log(err.message)}
};

export const fetchUserPendingRewards = async account => { 
  const web3 = getWeb3NoAccount();
  const contract = getTheAnalystContract(web3);

  try {
    const res = await contract.methods.getMyPoints().call({ from: account });
    if(res == 0){
      return "0"
    }
    const rewards = await contract.methods.calculatePointSell(res).call();
    return new BigNumber(rewards).toString();
  } catch (err) {
    console.log(err.message);
  }
};

export const fetchUserWorkers = async account => {
  const web3 = getWeb3NoAccount();
  const contract = getTheAnalystContract(web3);

  try {
    const res = await contract.methods.getMyMiners().call({ from: account });
    return new BigNumber(res).toString();
  } catch (err) {
    console.log(err.message);
  }
};
