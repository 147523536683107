import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useAppDispatch } from 'store';
import { updateUserStakedBalance, updateUserBalance, updateUserPendingReward } from 'store/actions';
import { claim } from 'utils/callHelpers';
import { useTheAnalyst } from './useContract';

export const useClaim = () => {
  const dispatch = useAppDispatch();
  const { account } = useWeb3React();
  const degenHallContract = useTheAnalyst();

  const handleClaim = useCallback(
    async () => {
      await claim(degenHallContract, account);

      dispatch(updateUserStakedBalance(account));
      dispatch(updateUserBalance(account));
      dispatch(updateUserPendingReward(account));
    },
    [account, dispatch, degenHallContract],
  );

  return { onClaim: handleClaim };
};

export default useClaim;
