export const  blacklist = [
  "0x581D01bb0c97d1Fa596DF42Dc6142568c4335737",
  // "0xa6d6Ea94482279f604b8ba2AD3aB1008af4ABF49", Removed from blacklist on 17/7
  "0xAC0F7ffdfC96b15fC1d1C9EbaFdd013139954Ab7",
  // "0xB0B05882E8f85eC96954adA3419203cdD019B7B2", Removed from blacklist on 17/7
  // "0xa3d1d1aC264014bE5bcb8e1B4587e0f722e42d9c", Removed from blacklist on 10/7
  // "0xbCc266902B5a3E1365d4CB710Af72Ae5341a3E0d", Removed from blacklist on 4/7
  // "0xC631982a0056A6D39c141002FfB2A2c6BB346372", Removed from blacklist on 8/7
  // "0x6791F85410134A66e073B12836Dc5736396791d4", Removed from blacklist on 8/7
  "0xd83c290d9beaa68eec4090be0fd3ab3e5d64e12a",
  "0xB368Ec942EF2979AA0D76634516C76C6Db8fe62a",
  // "0x971cC1BeB35591545C7Ff387A47115D5b05509D2", Removed from blacklist on 2/7
  // "0x8e596eC863fa38AB4d9e586c4490d596F6bD2998" Removed from blacklist on 2/7
  // "0x5Cde871cE4103383fcE51b619D7eb9A926Bb1C18" Removed from blacklist on 17/7
];

