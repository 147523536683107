import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { ArrowRepeat } from 'react-bootstrap-icons';
import { usePresale } from 'hooks/usePresale';
import { toast } from 'react-toastify';
import BigNumber from 'bignumber.js';
import { PRESALE_MAX_BUY_SIZE, DEFAULT_TOKENS_PER_ETH, PRESALE_MAX_BUY_SIZE_BN } from '../../config/constants';
import useAuth from 'hooks/useAuth';

const PresaleComponent = function ({ pendingTx, staking, setStaking, ethBalance, tokensPerETH, pending, status, account, max}) {
  const { onPresaleBuy, onPresaleClaim } = usePresale();

  const {login} = useAuth();
  const [_staking, set_Staking] = useState(staking);
  const [stakeAmount, setStakeAmount] = useState(0);
  const [tokenAmount, setTokenAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);

  const handleClickOnConfirm = useCallback(async () => {
    setStaking(true);
    set_Staking(true);

    try {
      await onPresaleBuy(stakeAmount);
      toast.success(`Transaction successfull!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      });
    } catch (err) {
      console.log(err);
      toast.error(`Transaction encountered an error!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      });
    }

    setStaking(false);
    set_Staking(false);
  }, [onPresaleBuy, stakeAmount, setStaking]);

  const handleClaimOnConfirm = useCallback(async () => {
    setStaking(true);
    set_Staking(true);

    try {
      await onPresaleClaim();
      toast.success(`Transaction successfull!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      });
    } catch (err) {
      console.log(err);
      toast.error(`Transaction encountered an error!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      });
    }

    setStaking(false);
    set_Staking(false);
  }, [onPresaleBuy, stakeAmount, setStaking]);

  // const setPercentageBalance = (percentage) => ethBalance === '0' ? setStakeAmount(0) : setStakeAmount(Number(new BigNumber(ethBalance).times(percentage).div(100).shiftedBy(-18).toFixed(5)))
  const getMaxAmount = () => Math.max(maxAmount, PRESALE_MAX_BUY_SIZE);

  // useEffect(() => {
  //   if(ethBalance){
  //     setPercentageBalance(90)
  //   }
  // }, [ethBalance])

  useEffect(() => {
    const _m = new BigNumber(max > 0 ? max : PRESALE_MAX_BUY_SIZE_BN).shiftedBy(-18)
    setMaxAmount(_m.toNumber());
    const defaultAmount = new BigNumber(ethBalance).shiftedBy(-18);
    if(defaultAmount.gt(_m)){
      setStakeAmount(_m.toNumber())
    }else{
      setStakeAmount(Number(defaultAmount.toFixed(6)))
    }
  }, [max])

  useEffect(() => {
    const amountBN = new BigNumber(stakeAmount);
    // console.log(amountBN);
    // console.log(maxAmount);
    const _m = getMaxAmount();
    // console.log(`_m: ${_m}`);
    const finalAmount = amountBN.gt(_m) ? _m : amountBN;
    // console.log(finalAmount.toNumber())
    setTokenAmount(finalAmount.multipliedBy(DEFAULT_TOKENS_PER_ETH).toNumber());
  }, [stakeAmount]);

  return (
    <Row className="mt-md-4 justify-content-center h-100">
      <Col md={6} xs={7} className="justify-content-center px-2">
        {/* <h5 className="font-medium text-center mb-md-3 text-uppercase" style={{fontSize: '0.8rem'}}>Buy AI Workers with ETH</h5> */}
        <Form>
          <Form.Control
            className="border-grey text-value font-medium bold text-center"
            value={stakeAmount}
            placeholder="0.01"
            type="float"
            step="any"
            onChange={e => {
              const _m = getMaxAmount();
              if(Number(e.target.value) > _m){
                setStakeAmount(_m)
              }else{
                setStakeAmount(e.target.value)
              }
            }}
            aria-describedby="basic-addon1"
            id={"stake_input"}
            max={maxAmount}
          />
        </Form>
      </Col>
      {/* <Col md={2} xs={5} className='px-2'>
        <Button
          className="btn-white btn-block"
          onClick={() => {
            if(new BigNumber(PRESALE_MAX_BUY_SIZE_BN).lt(ethBalance)){
              setStakeAmount(PRESALE_MAX_BUY_SIZE_BN)
            }else{
              setPercentageBalance(90);
            }
          }}>MAX</Button>
      </Col> */}
      <Col md={12} className="justify-content-center px-1">
        <div className="mt-4 mb-2">
          {
            account ? 
              status < 2
                ? <Button
                  className="w-100 px-md-5 btn-primary py-3 text-uppercase bold"
                  onClick={handleClickOnConfirm}
                  disabled={
                    pendingTx || _staking ||
                    parseFloat(stakeAmount) === 0 ||
                    stakeAmount.length === 0 ||
                    status != 1
                  }
                  style={{maxWidth: '20rem'}}
                  >{
                      status == 0 ?
                        'Buy MSITH' :
                        (!_staking) ? `Buy ${tokenAmount} MSITH` : 'Confirm transaction...'
                    }
                    {(_staking) && (<ArrowRepeat className="icon rotate ml-2" />)}
                  </Button>
                : <Button
                  className="w-100 btn-primary py-3 text-uppercase bold"
                  onClick={handleClaimOnConfirm}
                  disabled={
                    pendingTx || _staking ||
                    pending === 0 ||
                    status != 2 ||
                    pending == "0"
                  }>
                    {(!_staking) ? `Claim ${new BigNumber(pending).shiftedBy(-18).toString()} MSITH` : 'Confirm transaction...'}
                    {(_staking) && (<ArrowRepeat className="icon rotate ml-2" />)}
                  </Button>
              : <Button
                  className="w-100 btn-primary py-3 text-uppercase bold"
                  onClick={login}
                >Connect Wallet</Button>
          }
        </div>
      </Col>
    </Row>
  );
};

PresaleComponent.propTypes = {
  stakingMax: PropTypes.string,
  pendingTx: PropTypes.bool,
  staking: PropTypes.bool,
  setStaking: PropTypes.function,
  allowance: PropTypes.string,
  tokensPerETH: PropTypes.string,
  status: PropTypes.number,
  account: PropTypes.string,
  max: PropTypes.string
};

export default PresaleComponent;
