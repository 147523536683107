import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import MainContainer from 'components/MainContainer';
import { usePresale } from 'store/hooks';
import { propTypes } from 'react-bootstrap/esm/Image';
import PresaleComponent from 'components/PresaleComponent';
import { DEFAULT_TOKENS_PER_ETH } from 'config/constants';
import { BigNumber } from 'bignumber.js';
import usePrice from 'hooks/usePrice';
import { PRESALE_MAX_BUY_SIZE_BN } from 'config/constants';

const Presale = function ({ account }) {
  const presale = usePresale(account);
  const priceFeed = usePrice();

  const [buying, setBuying] = useState(false);
  const [claiming, setClaiming] = useState(false);
  const [ethPrice, setEthPrice] = useState(0);

  const getTokenPrice = () => {
    if(ethPrice == 0) return 0;
    const tokensPerEth = presale.userData?.tokensPerEth > 0 ? presale.userData?.tokensPerEth : DEFAULT_TOKENS_PER_ETH;
    const ethBN = new BigNumber(1).shiftedBy(18);
    return ethBN.dividedBy(tokensPerEth).multipliedBy(ethPrice).shiftedBy(-18).toFixed(5);
  }

  useEffect(() => {
    if(priceFeed)
      setEthPrice(Number(priceFeed['usd']));
  },[priceFeed])

  useEffect(() => {
    console.log(presale)
  }, [presale])

  return (
    <>
      <MainContainer className="pt-5">

      <Row className="d-flex justify-content-center py-5">
        <Col xs={12}>
          <h4>Welcome to the MSITH Token Presale</h4>
        </Col>
      </Row>
        <Row className="d-flex justify-content-center">
          <Col md={8} xs={12} className="card-container text-center">
            <Card>
              <Card.Header>
              <Row className="d-flex justify-content-start">
                <Col xs={12}>
                  <p className='m-0'>
                    Presale will last for <b>72 hours</b>.
                  </p>
                </Col>
              </Row>
              </Card.Header>
              <Card.Body>
                <PresaleComponent 
                  pendingTx={buying || claiming}
                  isStaking={buying}
                  setStaking={setBuying}
                  ethBalance={presale.userData?.ethBalance ?? 0}
                  tokensPerETH={presale.userData?.tokensPerEth > 0 ? presale.userData?.tokensPerEth : DEFAULT_TOKENS_PER_ETH}
                  pending={presale.userData?.pending ?? 0}
                  status={presale.status ?? 0}
                  account={account}
                  max={presale.userData?.max ?? PRESALE_MAX_BUY_SIZE_BN}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} xs={12} className="card-container text-center pt-3 pt-md-0">
            <Card>
              <Card.Header>
                <h5 className='m-0'>Presale info</h5>
              </Card.Header>
              <Card.Body className='px-2 px-md-4'>
                <div className='d-flex justify-content-between'>
                {
                  presale.status == 1 
                  ? <span class="badge bg-success text-white ">Presale started</span>
                  : <span class="badge bg-danger text-white">Presale not started</span>
                }{
                  presale.status == 2 
                  ? <span class="badge bg-success text-white">Claim available</span>
                  : <span class="badge bg-danger text-white">Claim not available</span>
                }
                </div>
                <hr />
                <span className='text-dark text-left d-block mt-2'><b>MSITH presale price</b>: {getTokenPrice()} $</span>
                <span className='text-dark text-left d-block mt-2'><b>Your bag</b>: {new BigNumber(presale.userData?.pending ?? 0).shiftedBy(-18).toString()} MSITH</span>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </MainContainer>
    </>
  );
};

Presale.propTypes = {
  account: propTypes.string,
};

export default Presale;
