import { createSlice } from '@reduxjs/toolkit';
import { presaleAddress } from 'config/constants';
import { getAddress } from 'utils/addressHelpers';
import { fetchPresaleUserInfo } from './fetchPresaleUser';
import { fetchPresaleStatus } from './fetchPresale';

const initialState = { data: {
  address: getAddress(presaleAddress)
}};

export const PresaleSlice = createSlice({
  name: 'Presale',
  initialState,
  reducers: {
    setPresaleUserData: (state, action) => {
      const userData = action.payload;
      state.data = { ...state.data, userData };
    },
    updatePresaleUserData: (state, action) => {
      const { field, value } = action.payload;
      state.data = { ...state.data, userData: { ...state.data.userData, [field]: value } };
    },
    setPresaleData: (state, action) => {
      const publicData = action.payload;
      state.data = { ...state.data, ...publicData };
    },
    updatePresaleData: (state, action) => {
      const { field, value } = action.payload;
      state.data = { ...state.data, publicData: { ...state.data.publicData, [field]: value } };
    },
  },
});

// Actions
export const { setPresaleUserData, updatePresaleUserData, setPresaleData, updatePresaleData } = PresaleSlice.actions;

export const fetchPresaleUserDataAsync = account => async dispatch => {
  const info = await fetchPresaleUserInfo(account);
  dispatch(setPresaleUserData(info));
};

export const updatePresaleUserInfo = account => async dispatch => {
  const info = await fetchPresaleUserInfo(account);
  dispatch(updatePresaleUserData(info));
};

export const fetchPresaleDataAsync = () => async dispatch => {
  const status = await fetchPresaleStatus();
  dispatch(setPresaleData(status));
};

export const updatePresalePublicData = () => async dispatch => {
  const data = await fetchPresaleStatus();
  dispatch(updatePresaleData(data));
};

export default PresaleSlice.reducer;
