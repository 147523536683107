import web3NoAccount from 'utils/web3';

import bep20Abi from 'config/abi/erc20.json';
import lpTokenABI from 'config/abi/lpToken.json';
import ABI from 'config/abi/TheAnalyst.json';
import PRESALE_ABI from 'config/abi/MSithV2Presale.json';
import { contractAddress, presaleAddress } from 'config/constants';
import { getAddress } from './addressHelpers';

const getContract = (abi, address, web3) => {
  const _web3 = web3 ?? web3NoAccount;
  return new _web3.eth.Contract(abi, address);
};

export const getBep20Contract = (address, web3) => getContract(bep20Abi, address, web3);
export const getLpTokenContract = (address, web3) => getContract(lpTokenABI, address, web3);
export const getTheAnalystContract = web3 => getContract(ABI, getAddress(contractAddress), web3);
export const getPresaleContract = web3 => getContract(PRESALE_ABI, getAddress(presaleAddress), web3);
