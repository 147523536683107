import BigNumber from 'bignumber.js';
import { getWeb3NoAccount } from 'utils/web3';
import { getPresaleContract } from 'utils/contractHelpers';

export const fetchUserBalance = async account => {
  const web3 = getWeb3NoAccount();
  const balance = await web3.eth.getBalance(account);
  return new BigNumber(balance).toJSON();
};

export const fetchPresaleUserInfo = async account => { 
  const web3 = getWeb3NoAccount();
  const contract = getPresaleContract(web3);

  try {
    const info = await contract.methods.info(account).call({ from: account });
    const pending = await contract.methods.pending(account).call({ from: account });
    const ethBalance = await web3.eth.getBalance(account);
    const full = { ...info, ethBalance, pending }
    return full
  } catch (err) {
    console.log(err.message);
  }
};
