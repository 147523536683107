import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { fetchPrices } from './prices';
import { fetchHallPublicDataAsync, fetchHallUserDataAsync, setBlock, fetchPresaleUserDataAsync, fetchPresaleDataAsync } from './actions';
import useRefresh from 'hooks/useRefresh';
import { getWeb3NoAccount } from 'utils/web3';

export const useBlock = () => useSelector(store => store.block);

export const useFetchPublicData = () => {
  const dispatch = useAppDispatch();
  const { slowRefresh } = useRefresh();

  useEffect(() => {
    dispatch(fetchHallPublicDataAsync());
  }, [dispatch, slowRefresh]);

  useEffect(() => {
    const web3 = getWeb3NoAccount();
    const interval = setInterval(async () => {
      const blockNumber = await web3.eth.getBlockNumber();
      dispatch(setBlock(blockNumber));
    }, 6000);

    return () => clearInterval(interval);
  }, [dispatch]);
};

export const useFetchPriceList = () => {
  const { slowRefresh } = useRefresh();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchPrices());
  }, [dispatch, slowRefresh]);
};

export const useGetApiPrices = () => {
  const ethPrice = useSelector(store => store.prices.data);
  return ethPrice;
};

export const useGetApiPrice = () => {
  const ethPrice = useGetApiPrices();

  if (!ethPrice) {
    return null;
  }

  return ethPrice;
};

export const useHall = account => {
  const { fastRefresh } = useRefresh();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (account) {
      dispatch(fetchHallUserDataAsync(account));
    }
  }, [account, dispatch, fastRefresh]);

  const hall = useSelector(store => store.hall.data);

  return hall;
};

export const usePresale = account => {
  const { fastRefresh } = useRefresh();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (account) {
      dispatch(fetchPresaleUserDataAsync(account));
      dispatch(fetchPresaleDataAsync());
    }
  }, [account, dispatch, fastRefresh]);

  const presale = useSelector(store => store.presale.data);
  return presale;
};
