import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useAppDispatch } from 'store';
import { updatePresaleUserInfo } from 'store/actions';
import { claimPresale, buyPresale } from 'utils/callHelpers';
import { usePresaleContract } from './useContract';

export const usePresale = () => {

  const dispatch = useAppDispatch();
  const { account } = useWeb3React();
  const presaleContract = usePresaleContract();

  const handleBuy = useCallback(
    async (amount) => {
      await buyPresale(presaleContract, amount, 18, account);
      dispatch(updatePresaleUserInfo(account));
    },
    [account, dispatch, presaleContract],
  );

  const handleClaim = useCallback(
    async () => {
      await claimPresale(presaleContract, account);
      dispatch(updatePresaleUserInfo(account));
    },
    [account, dispatch, presaleContract],
  );

  return { onPresaleBuy: handleBuy, onPresaleClaim: handleClaim };
};

export default usePresale;
