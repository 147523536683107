import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { ArrowRepeat } from 'react-bootstrap-icons';
import { useStake } from 'hooks/useStake';
import { toast } from 'react-toastify';
import BigNumber from 'bignumber.js';
// import { useApprove } from 'hooks/useApprove';

const StakeComponent = function ({ pendingTx, staking, setStaking, referall, ethBalance}) {
  const { onStake } = useStake(referall);
  // const { onApprove } = useApprove();

  const [stakeAmount, setStakeAmount] = useState(0);

  // const balance = getBalanceNumber(stakingMax, 18);
  const [_staking, set_Staking] = useState(staking);
  // const [approving, setApproving] = useState(false);

  const handleClickOnConfirm = useCallback(async () => {
    setStaking(true);
    set_Staking(true);

    try {
      await onStake(stakeAmount, 18);
      toast.success(`Transaction successfull!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      });
    } catch (err) {
      console.log(err);
      toast.error(`Transaction encountered an error!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      });
    }

    setStaking(false);
    set_Staking(false);
  }, [onStake, stakeAmount, setStaking]);

  useEffect(() => setPercentageBalance(90), [])

  const setPercentageBalance = (percentage) => ethBalance === '0' ? setStakeAmount(0) : setStakeAmount(Number(new BigNumber(ethBalance).times(percentage).div(100).shiftedBy(-18).toFixed(5)))

  // const handleApprove = useCallback(async () => {
  //   setApproving(true);
  //   try {
  //     await onApprove();
  //     toast.success(`Transaction successfull!`, {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: 'dark'
  //       });
  //   } catch (err) {
  //     console.log(err);
  //     toast.error(`Transaction encountered an error!`, {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: 'dark'
  //       });
  //   }
  //   setApproving(false);
  // }, [onApprove]);

  return (
    <Row className="mt-md-4">
      <Col md={12} className="justify-content-center">
        {/* <h5 className="font-medium text-center mb-md-3 text-uppercase" style={{fontSize: '0.8rem'}}>Buy AI Workers with ETH</h5> */}
        <Form>
          <Form.Control
            className="border-none text-value font-medium bold text-center"
            value={stakeAmount}
            placeholder="0.01"
            type="float"
            step="any"
            onChange={e => setStakeAmount(e.target.value)}
            aria-describedby="basic-addon1"
            id={"stake_input"}
          />
        </Form>
      </Col>
      <Col md={12} className="d-flex justify-content-between mt-2">
        <Button
          className="btn-white py-1"
          onClick={() => setPercentageBalance(25)}>25%</Button>
        <Button
          className="btn-white py-1"
          onClick={() => setPercentageBalance(50)}>50%</Button>
        <Button
          className="btn-white py-1"
          onClick={() => setPercentageBalance(75)}>75%</Button>
        <Button
          className="btn-white py-1"
          onClick={() => setPercentageBalance(95)}>95%</Button>
      </Col>
      <Col md={12} className="justify-content-center">
        <div className="mt-4 mb-2">
          <Button
            className="w-100 btn-primary py-3 text-uppercase bold"
            onClick={handleClickOnConfirm}
            disabled={
              pendingTx || _staking ||
              parseFloat(stakeAmount) === 0 ||
              stakeAmount.length === 0
            }>
            {(!_staking) ? 'Buy AI Workers' : 'Confirm transaction...'}
            {(_staking) && (<ArrowRepeat className="icon rotate ml-2" />)}
          </Button>
        </div>
      </Col>
    </Row>
  );
};

StakeComponent.propTypes = {
  stakingMax: PropTypes.string,
  pendingTx: PropTypes.bool,
  staking: PropTypes.bool,
  setStaking: PropTypes.func,
  allowance: PropTypes.string,
  referall: PropTypes.string
};

export default StakeComponent;
