import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_PRICES } from 'config/constants/endpoints';

const initialState = {
  isLoading: false,
  lastUpdated: null,
  data: null,
};

export const fetchPrices = createAsyncThunk('prices/fetch', async () => {
  const response = await fetch(`${API_PRICES}?ids=ethereum&vs_currencies=usd`);
  const data = await response.json();

  let result = {
    updatedAt: Math.floor(Date.now() / 1000),
    data: data['ethereum']
  }
  
  return result;
});

export const pricesSlice = createSlice({
  name: 'prices',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(fetchPrices.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchPrices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.lastUpdated = action.payload.updatedAt;
        state.data = action.payload.data;
      });
  },
});

export default pricesSlice.reducer;
