import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Form, Button } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import MainContainer from 'components/MainContainer';
import Hall from 'components/Hall';
import ReactTooltip from 'react-tooltip';
import { DEV_FEES, MARKETING_FEES } from 'config/constants';
import { useHall } from 'store/hooks';
import { propTypes } from 'react-bootstrap/esm/Image';
import MSITH2_IMG from '../assets/images/msith2.JPG';

const Dashboard = function ({ account }) {
  const hall = useHall(account);
  const [isCopied, setIsCopied] = useState(false);
  const [refLink, setRefLink] = useState(`${window.location.origin}/?ref=${account}`);
  // const [workers, setWorkers] = useState(0);

  useEffect(() => {
    setRefLink(`${window.location.origin}/?ref=${account}`);
  }, [account]);

  // useEffect(() => {
  //   if(hall.userData){
  //     setWorkers(hall.userData.workers);
  //   }
  // }, [hall])

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    }
    return document.execCommand('copy', true, text);
  }

  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(refLink)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <>
      <MainContainer className="pt-5">

        <Row className="d-flex justify-content-center pt-5">
          <Col xs={12}>
            <h4>Welcome to MSITH</h4>
            <p>
              Buy AI-Workers with ETH and let them seize the best trading opportunities for you on the Base network.<br></br>
              Note: <i>Profits are generated in real time and can be withdrawn at any time.</i>
            </p>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center pt-5">
          <Col xs={12}>
            <h4 className="text-center text-uppercase">MSITH Token presale is live!</h4>
            <div className="d-flex w-100 justify-content-center">
              <a
                className="w-100 btn-primary text-uppercase bold p-2 text-center"
                href={'/presale'}
              >Go to presale page</a>
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center pt-3">
          <Col md={8} xs={12} className="card-container text-center">
            <Card>
              
              <Card.Body>
                <Row className="d-flex justify-content-center">
                  <Col xs={12} className="text-center">
                    <img src={MSITH2_IMG} className="img-fluid" />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} xs={12} className="pr-md-0 mt-4 mt-md-0">
            <Hall hall={hall} />
          </Col>
        </Row>

        <Row className="d-flex justify-content-center mt-4">
          <Col md={12}>
            <Row>
              {/* History */}
              <Col md={6} className="mb-4 pl-md-0">
                <Card className="pool h-100">
                  <Card.Header>
                    <h4 className="text-uppercase text-center font-weight-bold text-title mb-0">MSITH historical data</h4>
                  </Card.Header>
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center row-apr">
                      <div className="font-medium apy">MSITH daily profit</div>
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="text-value font-medium bold">
                          <span> &gt; 5% &#10141; 6.5% / day</span>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center row-apr">
                      <div className="font-medium apy">
                        MSITH performance fee&nbsp;
                        <InfoCircle data-tip="A 3% commission will be deducted from operations as compensation to the developers." />
                        <ReactTooltip />
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="text-value font-medium bold">
                          <span>{DEV_FEES + MARKETING_FEES} %</span>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              {/* Referral */}
              {/* <Col md={6} className="mb-4 pr-md-0">
                <Card className='pool-alt'>
                  <Card.Header><h5 className="text-center text-uppercase bold mb-0">MSITH REFERRAL SYSTEM</h5></Card.Header>
                  <Card.Body>
                    <p className="mx-4 text-center text-desc">
                      Get free workers from anyone who uses your referral link
                    </p>
                    <div className="d-flex justify-content-between align-items-center row-apr">
                      <Form className="w-100 mr-4">
                        <Form.Control
                          className="text-value font-small ref-input p-3 mx-3 w-100"
                          value={refLink}
                          placeholder={refLink}
                          type="text"
                          disabled={true}
                        />
                      </Form>
                      <Button
                        className="btn-white py-1 px-4"
                        onClick={handleCopyClick}
                      >{isCopied ? 'Copied!' : 'Copy'}</Button>
                    </div>
                    <p style={{fontSize: '.7rem'}} className='text-center mb-0 mt-3'>
                      The referral bonus will be canceled for those who create multiple accounts.
                    </p>
                  </Card.Body>
                </Card>
              </Col> */}
            </Row>
          </Col>
        </Row>

      </MainContainer>
    </>
  );
};

Dashboard.propTypes = {
  account: propTypes.string,
};

export default Dashboard;
