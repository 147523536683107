import { useDispatch } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import blockReducer from './block';
import pricesReducer from './prices';
import hallReducer from './analyst';
import presaleReducer from './presale';

const store = configureStore({
  reducer: {
    block: blockReducer,
    hall: hallReducer,
    prices: pricesReducer,
    presale: presaleReducer
  },
});

export const useAppDispatch = () => useDispatch();

export default store;
